import React from "react";
import styled from "styled-components";
import GlobalButton from "./GlobalButton";
import picture from "./assets/join.jpg";

const Join = () => {
  return (
    <Container>
      <Content>Join our global marketplace today</Content>

      <ButtonHold>
        <GlobalButton
          width="230px"
          height="85px"
          color="black"
          backgroundColor="#ffff"
          border=""
          borderRadius="40px"
          content="Find Work"></GlobalButton>
        <GlobalButton
          width="260px"
          height="85px"
          color="black"
          backgroundColor="#56c870"
          border=""
          borderRadius="40px"
          content="Discover Talents"></GlobalButton>
      </ButtonHold>
    </Container>
  );
};

export default Join;

const Container = styled.div`
  width: 100%;
  height: 540px;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${picture});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  flex-direction: column;
  position: relative;
  background-attachment: fixed;
  ::before {
    content: "";
    height: 100%;
    width: 100%;
    background-color: #173b3f;
    opacity: 0.8;
    position: absolute;
  }
`;
const Content = styled.div`
  width: 80%;
  display: flex;
  margin-bottom: 40px;
  flex-direction: column;
  z-index: 10;
  font-size: 50px;
  color: white;
  text-align: center;
  font-family: Serif, "Garamond", serif;
`;
const ButtonHold = styled.div`
  display: flex;
`;
