import React from "react";
import styled from "styled-components";
import GlobalButton from "./GlobalButton";
import picture from "./assets/forengineer.jpg";

const Hero = () => {
  return (
    <Container>
      <Content>
        <h1>
          Find work that you
          <br /> love with companies
          <br /> you can trust
        </h1>
        <p>
          Join the Andela Talent Network to experience the joy of long-term
          work,
          <br /> with vetted companies and competitive compensation.
        </p>
      </Content>
      <ButtonHold>
        <GlobalButton
          width="450px"
          height="90px"
          color="black"
          backgroundColor="#56c870"
          border=""
          borderRadius="50px"
          content="Apply to access exclusive jobs"></GlobalButton>
      </ButtonHold>
    </Container>
  );
};

export default Hero;

const ButtonHold = styled.div`
  display: flex;
`;

const Container = styled.div`
  width: 100%;
  height: 820px;
  background-color: black;
  display: flex;
  justify-content: center;
  background-image: url(${picture});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  flex-direction: column;
  position: relative;
  background-attachment: fixed;
  ::before {
    content: "";
    height: 100%;
    width: 100%;
    background-color: #173b3f;
    opacity: 0.6;
    position: absolute;
  }
`;
const Content = styled.div`
  width: 80%;
  display: flex;
  margin-left: 30px;
  margin-top: 55px;
  margin-bottom: 40px;
  flex-direction: column;
  z-index: 10;
  h1 {
    font-size: 80px;
    color: white;
    font-family: Serif, "Garamond", serif;
  }
  span {
    font-family: Serif, "Garamond", serif;
  }
  p {
    font-size: x-large;
    color: white;
  }
`;
