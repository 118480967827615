import React from "react";
import styled from "styled-components";
import GlobalButton from "./GlobalButton";
import picture from "./assets/grow.jpg";

const Grow = () => {
  return (
    <Container>
      <Content>
        Grow your business and accelerate
        <br /> innovation with Andela
      </Content>

      <GlobalButton
        width="190px"
        height="90px"
        color="black"
        backgroundColor="#56c870"
        border=""
        borderRadius="50px"
        content="Sign up"></GlobalButton>
    </Container>
  );
};

export default Grow;

const Container = styled.div`
  width: 100%;
  height: 580px;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${picture});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  flex-direction: column;
  position: relative;
  background-attachment: fixed;
  ::before {
    content: "";
    height: 100%;
    width: 100%;
    background-color: #173b3f;
    opacity: 0.9;
    position: absolute;
  }
`;
const Content = styled.div`
  width: 80%;
  display: flex;
  margin-bottom: 40px;
  flex-direction: column;
  z-index: 10;
  font-size: 50px;
  color: white;
  text-align: center;
  font-family: Serif, "Garamond", serif;
`;
