import React from "react";
import styled from "styled-components";

const Achieved = () => {
  return (
    <Container>
      <LeftWrap>
        <Content>
          <h1>
            We’ve achieved
            <br /> many great things
          </h1>
        </Content>
      </LeftWrap>
      <RightWrap>
        <Left>
          <Circle>2022</Circle>
          <Line></Line>
          <Circle>2021</Circle>
          <Line5></Line5>
          <Circle>2020</Circle>
          <Line></Line>
          <Circle>2019</Circle>
          <Line1></Line1>
          <Circle>2018</Circle>
          <Line3></Line3>
          <Circle>2017</Circle>
          <Line2></Line2>
          <Circle>2016</Circle>
          <Line></Line>
          <Circle>2015</Circle>
          <Line4></Line4>
          <Circle>2014</Circle>
        </Left>
        <Right>
          <Texts>
            <Title>We release our open marketplace</Title>
            <Subs>
              We unlock the ability for our users to interact directly with one
              another while launching new value-added services like healthcare.
            </Subs>
          </Texts>
          <Texts>
            <Title>$200M Series E fundingt</Title>
            <Subs>
              Led by Softbank Vision Fund 2 with participation from new investor
              Whale Rock and existing investors including Generation Investment
              Management, Chan Zuckerberg Initiative, and Spark Capital.
            </Subs>
          </Texts>
          <Texts>
            <Title>Global expansion announcement</Title>
            <Subs>
              After proving that remote work was the way of the future, we
              doubled down on our investment in global talent in emerging
              markets around the world.
            </Subs>
          </Texts>
          <Texts>
            <Title>Welcomed senior remote engineers</Title>
            <Subs>
              Senior engineers in Egypt and Ghana joined the Andela community
              creating our first-ever remote hubs.
            </Subs>
          </Texts>
          <Texts>
            <Title>Launched pan-African hub</Title>
            <Subs>
              In partnership with the Rwanda Development Board that integrates
              all government agencies responsible for the attraction, retention,
              and facilitation of investments in the national economy.
            </Subs>
          </Texts>
          <Texts>
            <Title>Launched Andela Learning Community (ALC) </Title>
            <Subs>
              We partnered with Google and Udacity to create a technical
              learning program accessible to aspiring developers in Kenya,
              Nigeria, and Uganda.
            </Subs>
          </Texts>
          <Texts>
            <Title>Launched an all-female cohort of engineers </Title>
            <Subs>
              To increase female representation in tech, Andela Kenya opened
              applications for an all-female contingent in the country’s capital
              of Nairobi.
            </Subs>
          </Texts>
          <Texts>
            <Title>Our community became international </Title>
            <Subs>
              After incredible success in Nigeria, we opened applications to
              Kenya, Ghana, and South Africa with a new hub in Nairobi.
            </Subs>
          </Texts>
          <Texts>
            <Title>Launched our first recruitment cycle</Title>
            <Subs>
              We hired our first cohort – four Nigerian software engineers –
              after receiving 700 applications for 4 spots
            </Subs>
          </Texts>
        </Right>
      </RightWrap>
    </Container>
  );
};

export default Achieved;

const Container = styled.div`
  width: 100%;
  color: black;
  display: flex;
  padding-top: 15px;
  padding-bottom: 15px;
  position: relative;
`;
const Content = styled.div`
  width: 100%;
  display: flex;
  margin-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: black;

  flex-direction: column;
  z-index: 10;
  h1 {
    font-size: 50px;

    font-family: Serif, "Garamond", serif;
  }
  p {
    font-size: x-large;
  }
`;
const ButtonHold = styled.div`
  display: flex;
`;
const Content2 = styled.div`
  width: 80%;
  display: flex;
  margin-left: 30px;
  margin-top: 5px;
  margin-bottom: 40px;
  flex-direction: column;
  z-index: 10;
  p {
    font-size: x-large;
  }
`;
const LeftWrap = styled.div`
  z-index: 10;
  width: 50%;
`;
const RightWrap = styled.div`
  display: flex;
  z-index: 10;
  width: 50%;
  margin-top: 30px;
  position: relative;
`;
const Right = styled.div`
  font-size: x-large;
  width: 80%;
  margin-left: 100px;
  display: flex;
  flex-direction: column;
`;
const Texts = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  margin-top: 25px;
`;
const Title = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 10px;
`;
const Subs = styled.div`
  margin-bottom: 10px;
  margin-top: 10px;
`;
const Left = styled.div`
  position: absolute;
  top: 40px;
  width: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const Circle = styled.div`
  height: 80px;
  width: 80px;
  border-radius: 50%;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: x-large;
  font-weight: bold;
`;
const Line = styled.div`
  height: 105px;
  width: 0px;
  border: 1px solid black;
`;
const Line1 = styled.div`
  height: 105px;
  width: 0px;
  border: 1px solid black;
`;
const Line2 = styled.div`
  height: 135px;
  width: 0px;
  border: 1px solid black;
`;
const Line3 = styled.div`
  height: 155px;
  width: 0px;
  border: 1px solid black;
`;
const Line4 = styled.div`
  height: 105px;
  width: 0px;
  border: 1px solid black;
`;
const Line5 = styled.div`
  height: 165px;
  width: 0px;
  border: 1px solid black;
`;
