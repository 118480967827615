import React from "react";
import styled from "styled-components";
import Achieved from "./Achieved";
import BrilliantMinds from "./BrilliantMinds";
import Empower from "./Empower";
import Hero from "./Hero";
import Join from "./Join";
import OurInvestors from "./OurInvestors";
import Transforming from "./Transforming";

const About = () => {
  return (
    <Container>
      <Hero />
      <Empower />
      <BrilliantMinds />
      <OurInvestors />
      <Transforming />
      <Achieved />
      <Join />
    </Container>
  );
};

export default About;

const Container = styled.div`
  background-color: none;
`;
