import React from "react";
import styled from "styled-components";

const OurCommit = () => {
  return (
    <Container>
      <Left>
        Our commitment to
        <br /> your success
      </Left>
      <RightWrap>
        <Right>
          <Texts>
            <Title>Technologist first culture</Title>
            <Subs>
              Ensuring a seamless experience for technologists is our top
              priority. You can rest assured there is always someone looking out
              for your interests and career.
            </Subs>
          </Texts>
          <Texts>
            <Title>Trusted platform</Title>
            <Subs>
              We only work with trusted and vetted companies. No matter what,
              we’ll ensure you are paid for the work you do, and on time.
            </Subs>
          </Texts>
          <Texts>
            <Title>Global support</Title>
            <Subs>
              Wherever you are, and whatever issues you may face, we’re here to
              help solve any disputes, fast!
            </Subs>
          </Texts>
        </Right>
      </RightWrap>
    </Container>
  );
};

export default OurCommit;

const Container = styled.div`
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
`;
const Left = styled.div`
  width: 50%;
  font-size: 45px;
  margin-left: 20px;
  margin-top: 50px;
`;
const RightWrap = styled.div`
  display: flex;
  z-index: 10;
  width: 50%;
  margin-top: 30px;
`;
const Right = styled.div`
  font-size: x-large;
  display: flex;
  flex-direction: column;
  color: black;
`;
const Texts = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  margin-top: 15px;
`;
const Title = styled.div`
  font-size: 35px;
  margin-bottom: 10px;
  margin-top: 10px;
`;
const Subs = styled.div`
  margin-bottom: 10px;
  margin-top: 10px;
`;
