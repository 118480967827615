import React from "react";
import styled from "styled-components";
import GlobalButton from "./GlobalButton";
import picture from "./assets/backbuy.jpg";
import Header from "./Header";

const Hero = () => {
  return (
    <Container>
      <Content>
        <h1>
          Discover <span>brilliant</span> talent
          <br />
          around the world
        </h1>
        <p>
          Join Andela to build your team with developers, engineers, product
          designers, and the best remote technology experts.
        </p>
      </Content>
      <ButtonHold>
        <GlobalButton
          width="250px"
          height="90px"
          color="black"
          backgroundColor="#56c870"
          border=""
          borderRadius="50px"
          content="Hire Talents"></GlobalButton>
        <GlobalButton
          width="250px"
          height="90px"
          color="black"
          backgroundColor="white"
          border=""
          borderRadius="50px"
          content="Apply for Jobs"></GlobalButton>
      </ButtonHold>
    </Container>
  );
};

export default Hero;

const ButtonHold = styled.div`
  display: flex;
`;

const Container = styled.div`
  width: 100%;
  height: 820px;
  background-color: black;
  display: flex;
  justify-content: center;
  background-image: url(${picture});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  flex-direction: column;
  position: relative;
  background-attachment: fixed;
  ::before {
    content: "";
    height: 100%;
    width: 100%;
    background-color: #173b3f;
    opacity: 0.7;
    position: absolute;
  }
`;
const Content = styled.div`
  width: 80%;
  display: flex;
  margin-left: 30px;
  margin-top: 55px;
  margin-bottom: 40px;
  flex-direction: column;
  z-index: 10;
  h1 {
    font-size: 70px;
    color: white;
    font-family: Serif, "Garamond", serif;
  }
  span {
    font-family: Serif, "Garamond", serif;
  }
  p {
    font-size: x-large;
    color: white;
  }
`;
