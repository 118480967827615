import React from "react";
import styled from "styled-components";
import profile from "./assets/iconName.png";

interface Iprops {
  bg: string;
  image: any;
  name: string;
  country: string;
  about: string;
  imageElse: any;
}
const SliderCard: React.FC<Iprops> = ({
  bg,
  image,
  name,
  country,
  about,
  imageElse,
}) => {
  return (
    <Container>
      <Card bg={bg}>
        <ProfileContainer>
          <Profile src={image} />
          <Initials>
            <Name>{name}</Name>
            <SubName>{country}</SubName>
          </Initials>
          <Else src={imageElse} />
        </ProfileContainer>
        <p>{about}</p>
      </Card>
    </Container>
  );
};

export default SliderCard;

const Card = styled.div<{ bg: string }>`
  height: 290px;
  width: 290px;
  background: ${(props) => props.bg};
  padding: 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin: 10px;
  border-radius: 10px;
  p {
    font-size: x-large;
  }
`;
const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
`;
const Profile = styled.img`
  height: 40px;
  width: 40px;
  background-color: silver;
  border-radius: 50%;
  object-fit: cover;
`;
const Else = styled.img`
  height: 40px;
  background-color: silver;
  object-fit: cover;
`;
const Name = styled.div`
  margin-left: 20px;
  font-weight: bold;
`;
const SubName = styled.div`
  margin-left: 20px;
  font-size: smaller;
`;
const Container = styled.div``;
const Initials = styled.div`
  display: flex;
  flex-direction: column;
`;
