import React from "react";
import styled from "styled-components";
import logo from "./assets/logo.svg";
import { FaFacebook } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";

const Footer = () => {
  return (
    <Container>
      <Icon>
        <Logo src={logo} />
        <br />
        <br />
        <br />
        <Icons>
          <FaFacebook />
          <FaTwitter />
          <FaGithub />
          <FaInstagramSquare />
          <FaYoutube />
          <FaLinkedin />
        </Icons>
      </Icon>
      <First>
        <MainTitle>Discover Talent</MainTitle>
        <Content>
          <List>For Companies</List>
          <List>Enterprise</List>
          <List>Case studies</List>
        </Content>
      </First>
      <First>
        <MainTitle>Find Work</MainTitle>
        <Content>
          <List>For Technologists</List>
          <List>Learning Community</List>
          <List>Leadership Program</List>
          <List>Resources</List>
        </Content>
      </First>
      <First>
        <MainTitle>Andela</MainTitle>
        <Content>
          <List>About us</List>
          <List>Events</List>
          <List>Blog</List>
          <List>Press Center</List>
          <List>Careers</List>
        </Content>
      </First>
      <First>
        <Content>
          <List>Privacy Policy</List>
          <List>Terms and Conditions</List>
          <List>Modern Slavery Statement</List>
        </Content>
      </First>
    </Container>
  );
};

export default Footer;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-top: 70px;
  padding-bottom: 70px;
  background-color: #173b3f;
`;
const Icon = styled.div`
  display: flex;
  flex-direction: column;
`;
const Logo = styled.img`
  height: 73px;
`;
const Icons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: white;
  font-size: xx-large;
`;
const First = styled.div`
  color: white;
`;
const MainTitle = styled.div`
  margin-top: 7px;
  font-weight: bold;
`;
const Content = styled.div`
  margin-top: 10px;
`;
const List = styled.div`
  margin-top: 9px;
  margin-bottom: 9px;
  display: flex;
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;
