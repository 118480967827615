import React from "react";
import styled from "styled-components";
import BrilliantsComp from "./Brilliant";
import Community from "./Community";
import Connecting from "./Connecting";
import FindWhat from "./FindWhat";

import Hero from "./Hero";
import ManyFaces from "./ManyFaces";
import OurCommit from "./OurCommit";
import Spark from "./Spark";
import YourSkill from "./YourSkill";

const ForEngineers = () => {
  return (
    <Container>
      <Hero />
      <BrilliantsComp />
      <Community />
      <FindWhat />
      <Spark />
      <YourSkill />
      <ManyFaces />
      <OurCommit />
      <Connecting />
    </Container>
  );
};

export default ForEngineers;

const Container = styled.div`
  background-color: none;
`;
