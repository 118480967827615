import React from "react";
import styled from "styled-components";
import GlobalButton from "./GlobalButton";

const After = () => {
  return (
    <Container>
      <Text>
        Andela Launches New Platform
        <br /> to Power the Future of Customized Work
      </Text>
      <WrapButton>
        <GlobalButton
          width="230px"
          height="80px"
          color="black"
          backgroundColor="#56c870"
          border=""
          borderRadius="40px"
          content="Find out more"></GlobalButton>
      </WrapButton>
    </Container>
  );
};

export default After;

const Container = styled.div`
  width: 100%;
  height: 200px;
  background-color: #173b3f;
  display: flex;
  align-items: center;
`;
const Text = styled.div`
  color: white;
  font-size: 40px;
  margin-left: 40px;
`;
const WrapButton = styled.div`
  margin-left: 250px;
`;
