import React from "react";
import styled from "styled-components";
import picture from "./assets/about.jpg";

const Hero = () => {
  return (
    <Container>
      <Content>
        <h1>
          Our mission is to
          <br />
          connect brilliance
          <br />
          with opportunity
        </h1>
        <p>Irrespective of race, gender, and geography.</p>
      </Content>
    </Container>
  );
};

export default Hero;

const ButtonHold = styled.div`
  display: flex;
`;

const Container = styled.div`
  width: 100%;
  height: 820px;
  display: flex;
  background-image: url(${picture});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  flex-direction: column;
  position: relative;
  background-attachment: fixed;
  ::before {
    content: "";
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0.5;
    position: absolute;
  }
`;
const Content = styled.div`
  width: 80%;
  display: flex;
  margin-left: 30px;
  margin-top: 105px;
  margin-bottom: 40px;
  flex-direction: column;
  z-index: 10;
  h1 {
    font-size: 70px;
    color: white;
    font-family: Serif, "Garamond", serif;
  }
  span {
    font-family: Serif, "Garamond", serif;
  }
  p {
    font-size: x-large;
    color: white;
  }
`;
