import React from "react";
import styled from "styled-components";
import forCard2 from "./assets/Invest.png";

const OurInvestors = () => {
  return (
    <Container>
      <Card>
        <RightCard>
          <MainTitle>Our Investors</MainTitle>
          <Content>
            <List>
              <ListItem>
                <Text>
                  We are grateful to be backed by some of the best
                  <br />
                  in the world.
                </Text>
              </ListItem>
            </List>
          </Content>
        </RightCard>
        <LeftCard>
          <Pic src={forCard2} />
        </LeftCard>
      </Card>
    </Container>
  );
};

export default OurInvestors;
const Container = styled.div`
  display: flex;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
  flex-direction: column;
`;
const Title = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
`;
const Main = styled.div`
  font-size: 50px;
  margin-bottom: 20px;
`;
const Card = styled.div`
  width: 100%;
  display: flex;
  margin-top: 60px;
  margin-bottom: 60px;
`;
const LeftCard = styled.div`
  width: 45%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
  margin-right: 30px;
`;
const RightCard = styled.div`
  width: 46%;
  height: 90%;
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  margin-right: 30px;
`;
const MainTitle = styled.div`
  font-size: 50px;
  margin-top: 7px;
`;
const Content = styled.div`
  font-size: x-large;
  margin-top: 10px;
`;
const List = styled.div`
  margin-top: 5px;
  display: flex;
  align-items: center;
  flex-direction: row;
`;
const ListItem = styled.div`
  margin-top: 4px;
  margin-bottom: 4px;
  display: flex;
`;
const Text = styled.div`
  font-size: x-large;
  color: #6b6b6b;
  margin-left: 7px;
`;
const Pic = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;
