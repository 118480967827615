import React from "react";
import styled from "styled-components";

interface iProps {
  width: string;
  height: string;
  color: string;
  backgroundColor: string;
  border: string;
  borderRadius: string;
  content: string;
}

const GlobalButton: React.FC<iProps> = ({
  width,
  height,
  color,
  backgroundColor,
  border,
  borderRadius,
  content,
}) => {
  return (
    <Container
      width={width}
      height={height}
      color={color}
      backgroundColor={backgroundColor}
      border={border}
      borderRadius={borderRadius}>
      {(content = content)}
    </Container>
  );
};

export default GlobalButton;
const Container = styled.div<{
  width: string;
  height: string;
  color: string;
  backgroundColor: string;
  border: string;
  borderRadius: string;
}>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundColor};
  border: ${(props) => props.border};
  border-radius: ${(props) => props.borderRadius};
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: x-large;
  z-index: 10;
  transition: all 350ms;
  :hover {
    cursor: pointer;
    transform: scale(1.04);
  }
`;
