import React from "react";
import styled from "styled-components";
import GlobalButton from "./GlobalButton";
import left from "./assets/exthree.png";

const Exceptional = () => {
  return (
    <Container>
      <Left src={left} />
      <Right>
        <SubTitle>Build your team today</SubTitle>
        <MainTitle>
          We’ve cracked the code on
          <br /> creating engineering teams
        </MainTitle>
        <Content>
          Skilled engineers, product managers, and designers at your fingertips.
          An extensive list of technical services to suit your business needs.
          Start building your world-class team faster with talent from Andela.
        </Content>
        <WrapButton>
          <GlobalButton
            width="270px"
            height="90px"
            color="black"
            backgroundColor="#56c870"
            border=""
            borderRadius="50px"
            content="Discover Talent"
          />
        </WrapButton>
      </Right>
    </Container>
  );
};

export default Exceptional;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 700px;
  background-color: #173b3f;
  color: white;
`;
const Left = styled.img`
  width: 46%;
  object-fit: cover;
`;
const Right = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  margin-left: 120px;
`;
const SubTitle = styled.div`
  font-size: x-large;
  font-weight: bold;
  margin-top: 10px;
`;
const MainTitle = styled.div`
  font-size: 45px;
  margin-top: 10px;
`;
const Content = styled.div`
  font-size: x-large;
  margin-top: 15px;
`;
const WrapButton = styled.div`
  margin-top: 30px;
`;
