import React from "react";
import styled from "styled-components";
import GlobalButton from "./GlobalButton";

const WeOffer = () => {
  return (
    <Container>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Head>
        <Bold>We offer you peace of mind</Bold>
        <Paragraph>
          We understand that sometimes you want to find talent on your own
          <br /> and other times you want some help. We offer you the freedom to
          do both.
        </Paragraph>
      </Head>
      <br />
      <br />
      <Three>
        <Card>
          <TextWrap>
            <Number>Source</Number>
            <Content>
              We’ll work with you to fully understand your technical gaps,
              culture, industry regulations, and strategic route. Instantly view
              a curated selection of technologist profiles that meet your needs.
            </Content>
          </TextWrap>
        </Card>
        <Card>
          <TextWrap>
            <Number>Assess</Number>
            <Content>
              Our proprietary assessments are designed to find you the best
              talent by role, no matter where they are in the world. Based on
              your team’s goals, meet vetted technologists you can trust.
            </Content>
          </TextWrap>
        </Card>
        <Card>
          <TextWrap>
            <Number>Match</Number>
            <Content>
              Connect within hours, with vetted technologists who can easily
              align with your existing teams, tools, and workflows. Our matching
              times are consistently twice as fast compared to industry
              standards.
            </Content>
          </TextWrap>
        </Card>
        <Card>
          <TextWrap>
            <Number>Deliver</Number>
            <Content>
              With a focus on sustainable growth, we’re with you every step of
              the way. From rapid onboarding and seamless integration to global
              payroll solutions and compliance support.
            </Content>
          </TextWrap>
        </Card>
      </Three>
      <br />
      <br />
      <br />
      <br />
      <br />
    </Container>
  );
};

export default WeOffer;

const Container = styled.div`
  width: 100%;
  background-color: #f8f8f7;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const Head = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;
const Bold = styled.div`
  font-size: 40px;
  text-align: center;
`;
const Paragraph = styled.div`
  font-size: x-large;
  text-align: center;
  margin-top: 35px;
`;
const Three = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;
const Card = styled.div`
  height: 300px;
  width: 370px;
  border-radius: 30px;
  background-color: white;
  color: #606160;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const TextWrap = styled.div`
  width: 75%;
  height: 75%;
`;
const Number = styled.div`
  font-size: x-large;
`;
const Content = styled.div`
  margin-top: 10px;
  font-size: large;
`;
