import React from "react";
import styled from "styled-components";
import GlobalButton from "./GlobalButton";
import right from "./assets/three.png";
import { FcCheckmark } from "react-icons/fc";

const Empower = () => {
  return (
    <Container>
      <Left>
        <SubTitle>Empower your future</SubTitle>
        <MainTitle>
          Why the world’s best
          <br /> talent prefers Andela
        </MainTitle>
        <Content>
          <List>
            <ListItem>
              <FcCheckmark />
              <Text>Long-term placements</Text>
            </ListItem>
          </List>
          <List>
            <ListItem>
              <FcCheckmark />
              <Text>Trusted career partner</Text>
            </ListItem>
          </List>
          <List>
            <ListItem>
              <FcCheckmark />
              <Text>Career Guidance</Text>
            </ListItem>
          </List>
          <List>
            <ListItem>
              <FcCheckmark />
              <Text>96% match success</Text>
            </ListItem>
          </List>
        </Content>
        <WrapButton>
          <GlobalButton
            width="300px"
            height="80px"
            color="black"
            backgroundColor="#56c870"
            border=""
            borderRadius="50px"
            content="Find Tech Experts"
          />
        </WrapButton>
      </Left>
      <Right src={right} />
    </Container>
  );
};

export default Empower;

const Container = styled.div`
  width: 100%;
  height: 700px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;
const Left = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  margin-left: 60px;
`;
const SubTitle = styled.div`
  font-size: x-large;
  font-weight: bold;
  margin-top: 10px;
`;
const MainTitle = styled.div`
  font-size: 50px;
  margin-top: 7px;
`;
const Content = styled.div`
  font-size: x-large;
  margin-top: 10px;
`;
const WrapButton = styled.div`
  margin-top: 10px;
`;
const List = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
  flex-direction: row;
`;
const ListItem = styled.div`
  margin-top: 4px;
  margin-bottom: 4px;
  display: flex;
`;
const Icon = styled.img``;
const Text = styled.div`
  font-size: x-large;
`;
const Right = styled.img`
  width: 60%;
  object-fit: cover;
  margin-right: 70px;
`;
