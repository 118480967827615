import React from "react";
import styled from "styled-components";
import git from "./assets/git.svg";
import gol from "./assets/gol.svg";
import sei from "./assets/sei.svg";
import inv from "./assets/inv.svg";
import kra from "./assets/kra.svg";
import cou from "./assets/cou.svg";

const Trusted = () => {
  return (
    <Container>
      <Text>Trusted by</Text>
      <Wrap>
        <Logo src={git} />
        <Logo src={inv} />
        <Logo src={cou} />
        <Logo src={kra} />
        <Logo src={sei} />
        <Logo src={gol} />
      </Wrap>
    </Container>
  );
};

export default Trusted;

const Container = styled.div`
  width: 100%;
  height: 200px;
  background-color: #e0f1eb;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;
`;
const Text = styled.div`
  color: #173b3f;
  font-size: 40px;
  margin-left: 40px;
  margin-top: 10px;
`;
const Wrap = styled.div`
  width: 80%;
  margin-left: 250px;
  margin-top: 70px;
  display: flex;
  justify-content: space-between;
  margin-right: 250px;
`;
const Logo = styled.img`
  object-fit: contain;
  height: 32px;
  width: 80px;
`;
