import React from "react";
import styled from "styled-components";
import BuildBrilliant from "./BuildBrilliant";
import BusinessFindWhat from "./BusinessFindWhat";
import Elevate from "./Elevate";
import Exceptional from "./Exceptional";
import Footer from "./Footer";
import Grow from "./Grow";
import Hero from "./Hero";
import KeepAhead from "./KeepAhead";
import WeOffer from "./WeOffer";

const Business = () => {
  return (
    <Container>
      <Hero />
      <BusinessFindWhat />
      <Exceptional />
      <WeOffer />
      <BuildBrilliant />
      <KeepAhead />
      <Elevate />
      <Grow />
    </Container>
  );
};

export default Business;

const Container = styled.div`
  background-color: none;
`;
