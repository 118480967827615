import React from "react";
import styled from "styled-components";
import SliderCard from "./SliderCard";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import profile from "./assets/iconName.png";
import raph from "./assets/raph.jpg";
import adet from "./assets/adet.jpg";
import oneof1 from "./assets/oneof1.png";
import oneof2 from "./assets/oneof2.png";

const BrilliantsComp = () => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 4.6,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    // autoplaySpeed: 5000,
    cssEase: "linear",
  };
  return (
    <Container>
      <h1>
        Brilliant minds and companies
        <br /> come together to build the future
      </h1>

      <Wrapper>
        <Slider {...settings}>
          <SliderCard
            about="Andela has helped me to believe, to dream big, do the impossible."
            name="Raphael"
            country="Brasil"
            image={raph}
            bg="bisque"
            imageElse=""
          />
          <SliderCard
            about="Andela has helped me to believe, to dream big, do the impossible"
            name="ML"
            country="United States"
            image={profile}
            bg="#fcb4c3"
            imageElse=""
          />
          <SliderCard
            about="Andela has helped me to believe, to dream big, do the impossible"
            name="Kaji"
            country="Great Brittain"
            image={profile}
            bg="#6effc3"
            imageElse=""
          />
          <SliderCard
            about="Andela has helped me to believe, to dream big, do the impossible"
            name=""
            country=""
            image=""
            bg="silver"
            imageElse={oneof1}
          />
          <SliderCard
            about="Andela has helped me to believe, to dream big, do the impossible"
            name="Alexander"
            country="United States"
            image={profile}
            bg="#e081f8"
            imageElse=""
          />
          <SliderCard
            about="Andela has helped me to believe, to dream big, do the impossible"
            name=""
            country=""
            image=""
            bg="silver"
            imageElse={oneof2}
          />
          <SliderCard
            about="Andela has helped me to believe, to dream big, do the impossible"
            name="Adetola"
            country="Nigeria"
            image={adet}
            bg="#80bea9"
            imageElse={null}
          />
          <SliderCard
            about="Andela has helped me to believe, to dream big, do the impossible"
            name="Adeyinka"
            country="Nigeria"
            image={profile}
            bg="#fafc8f"
            imageElse=""
          />
        </Slider>
      </Wrapper>
    </Container>
  );
};

export default BrilliantsComp;

const Hold = styled.div`
  width: 100%;
  height: 400px;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 60px;
  padding-bottom: 50px;
  h1 {
    text-align: center;
    font-size: 40px;
  }
`;
const Wrapper = styled.div`
  /* display: flex; */
  width: 100%;
  /* height: 300px; */
  overflow: hidden;
  color: #474747;
  /* background-color: black; */
`;
