import React from "react";
import styled from "styled-components";
import After from "./After";
import Brilliant from "./Brilliant";
import BuildBrilliant from "./BuildBrilliant";
import Empower from "./Empower";
import Footer from "./Footer";
import Hero from "./Hero";
import ScaleFaster from "./ScaleFaster";
import TeamBuild from "./TeamBuild";
import Trusted from "./Trusted";
import Welcome from "./Welcome";

const StartFile = () => {
  return (
    <Container>
      <Hero />
      <After />
      <Trusted />
      <TeamBuild />
      <Empower />
      <BuildBrilliant />
      <Brilliant />
      <Welcome />
      <ScaleFaster />
    </Container>
  );
};

export default StartFile;

const Container = styled.div`
  background-color: none;
`;
