import React from "react";
import styled from "styled-components";
import picture from "./assets/back2.jpeg";
import GlobalButton from "./GlobalButton";

const BuildBrilliant = () => {
  return (
    <Container>
      <LeftWrap>
        <Content>
          <h1>
            Build a brilliant
            <br /> team quickly
          </h1>
          <p>
            Brilliant minds and companies come
            <br /> together to build the future.
          </p>
        </Content>
        <ButtonHold>
          <GlobalButton
            width="300px"
            height="90px"
            color="black"
            backgroundColor="#56c870"
            border=""
            borderRadius="50px"
            content="Build your dream team"></GlobalButton>
        </ButtonHold>
      </LeftWrap>
      <RightWrap>
        <Left>
          <Circle>1</Circle>
          <Line></Line>
          <Circle>2</Circle>
          <Line></Line>
          <Circle>3</Circle>
        </Left>
        <Right>
          <Texts>
            <Title>Smart skill matching</Title>
            <Subs>
              Our intuitive machine learning and AI platform finds you the
              <br /> best match for any role.
            </Subs>
          </Texts>
          <Texts>
            <Title>Meet the best talent</Title>
            <Subs>
              Easily schedule interviews and meet top technologists that fit
              <br />
              within your team.
            </Subs>
          </Texts>
          <Texts>
            <Title>Continued excellence</Title>
            <Subs>
              Integrate your new team members quickly and effectively. Let
              <br /> us manage payroll and compliance.
            </Subs>
          </Texts>
        </Right>
      </RightWrap>
    </Container>
  );
};

export default BuildBrilliant;

const Container = styled.div`
  width: 100%;
  height: 670px;
  background-color: black;
  display: flex;
  padding-top: 15px;
  padding-bottom: 15px;
  background-image: url(${picture});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  background-attachment: fixed;
  ::before {
    content: "";
    height: 100%;
    width: 100%;
    background-color: #173b3f;
    opacity: 0.3;
    position: absolute;
    top: 0;
  }
`;
const Content = styled.div`
  width: 100%;
  display: flex;
  margin-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  flex-direction: column;
  z-index: 10;
  h1 {
    font-size: 50px;
    color: white;
    font-family: Serif, "Garamond", serif;
  }
  p {
    font-size: x-large;
    color: white;
  }
`;
const ButtonHold = styled.div`
  display: flex;
`;
const Content2 = styled.div`
  width: 80%;
  display: flex;
  margin-left: 30px;
  margin-top: 5px;
  margin-bottom: 40px;
  flex-direction: column;
  z-index: 10;
  p {
    font-size: x-large;
    color: white;
  }
`;
const LeftWrap = styled.div`
  z-index: 10;
  width: 50%;
`;
const RightWrap = styled.div`
  display: flex;
  z-index: 10;
  width: 50%;
  margin-top: 30px;
  position: relative;
`;
const Right = styled.div`
  font-size: x-large;
  width: 80%;
  margin-left: 100px;
  display: flex;
  flex-direction: column;
  color: white;
`;
const Texts = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  margin-top: 25px;
`;
const Title = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 10px;
`;
const Subs = styled.div`
  margin-bottom: 10px;
  margin-top: 10px;
`;
const Left = styled.div`
  position: absolute;
  top: 40px;
  width: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const Circle = styled.div`
  height: 80px;
  width: 80px;
  border-radius: 50%;
  border: 1px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: xx-large;
  color: white;
`;
const Line = styled.div`
  height: 100px;
  width: 0px;
  border: 1px solid white;
`;
