import React from "react";
import styled from "styled-components";
import { FcCheckmark } from "react-icons/fc";
import forCard1 from "./assets/card1.jpg";
import forCard2 from "./assets/card2.jpg";
import forCard3 from "./assets/card3.jpg";

const FindWhat = () => {
  return (
    <Container>
      <Title>
        <Main>Find what you’re looking for and more</Main>
      </Title>
      <Card>
        <LeftCard>
          <Pic src={forCard1} />
        </LeftCard>
        <RightCard>
          <MainTitle>Grow your career with us</MainTitle>
          <Content>
            <List>
              <ListItem>
                <FcCheckmark />
                <Text>
                  Find meaningful, long-term work with interesting organizations
                </Text>
              </ListItem>
            </List>
            <List>
              <ListItem>
                <FcCheckmark />
                <Text>
                  Work with an internationally distributed team and company
                </Text>
              </ListItem>
            </List>
            <List>
              <ListItem>
                <FcCheckmark />
                <Text>
                  Gain additional skills through exclusive learning
                  opportunities
                </Text>
              </ListItem>
            </List>
            <List>
              <ListItem>
                <FcCheckmark />
                <Text>
                  Network with other technologists to develop your expertise
                </Text>
              </ListItem>
            </List>
          </Content>
        </RightCard>
      </Card>

      <Card>
        <RightCard>
          <MainTitle>Set your own rate</MainTitle>
          <Content>
            <List>
              <ListItem>
                <FcCheckmark />
                <Text>Only work with companies we trust</Text>
              </ListItem>
            </List>
            <List>
              <ListItem>
                <FcCheckmark />
                <Text>
                  Receive payments on time in your local currency, USD, or in
                  Crypto
                </Text>
              </ListItem>
            </List>
            <List>
              <ListItem>
                <FcCheckmark />
                <Text>
                  Discover salaries that match your skills and experience
                </Text>
              </ListItem>
            </List>
          </Content>
        </RightCard>
        <LeftCard>
          <Pic src={forCard2} />
        </LeftCard>
      </Card>
      <Card>
        <LeftCard>
          <Pic src={forCard3} />
        </LeftCard>
        <RightCard>
          <MainTitle>Work anytime, anywhere</MainTitle>
          <Content>
            <List>
              <ListItem>
                <FcCheckmark />
                <Text>Ditch the commute and work from anywhere</Text>
              </ListItem>
            </List>
            <List>
              <ListItem>
                <FcCheckmark />
                <Text>
                  With roles across different time zones, you choose when you
                  work
                </Text>
              </ListItem>
            </List>
            <List>
              <ListItem>
                <FcCheckmark />
                <Text>Create a healthy, flexible work-life balance</Text>
              </ListItem>
            </List>
          </Content>
        </RightCard>
      </Card>
    </Container>
  );
};

export default FindWhat;
const Container = styled.div`
  display: flex;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
  flex-direction: column;
`;
const Title = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
`;
const Main = styled.div`
  font-size: 50px;
  margin-bottom: 20px;
`;
const Card = styled.div`
  width: 100%;
  display: flex;
  margin-top: 60px;
  margin-bottom: 60px;
`;
const LeftCard = styled.div`
  width: 45%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
  margin-right: 30px;
`;
const RightCard = styled.div`
  width: 46%;
  height: 90%;
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  margin-right: 30px;
`;
const MainTitle = styled.div`
  font-size: xx-large;
  margin-top: 7px;
`;
const Content = styled.div`
  font-size: x-large;
  margin-top: 10px;
`;
const List = styled.div`
  margin-top: 5px;
  display: flex;
  align-items: center;
  flex-direction: row;
`;
const ListItem = styled.div`
  margin-top: 4px;
  margin-bottom: 4px;
  display: flex;
`;
const Text = styled.div`
  font-size: x-large;
  color: #6b6b6b;
  margin-left: 7px;
`;
const Pic = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;
