import React from "react";
import styled from "styled-components";
import GlobalButton from "./GlobalButton";
import picture from "./assets/manyfaces.jpg";

const ManyFaces = () => {
  return (
    <Container>
      <Content>
        <h1>Grow your potential beyond borders</h1>
        <p>
          Connect with a global network and land international opportunities.
        </p>
      </Content>
      <ButtonHold>
        <GlobalButton
          width="350px"
          height="85px"
          color="black"
          backgroundColor="#56c870"
          border=""
          borderRadius="50px"
          content="Discover opportunities"></GlobalButton>
      </ButtonHold>
    </Container>
  );
};

export default ManyFaces;

const ButtonHold = styled.div`
  display: flex;
`;

const Container = styled.div`
  width: 100%;
  height: 820px;
  background-color: black;
  display: flex;
  justify-content: center;
  background-image: url(${picture});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  flex-direction: column;
  position: relative;
  ::before {
    content: "";
    height: 100%;
    width: 100%;
    background-color: #173b3f;
    opacity: 0.8;
    position: absolute;
  }
`;
const Content = styled.div`
  width: 80%;
  display: flex;
  margin-left: 30px;
  margin-top: 55px;
  margin-bottom: 40px;
  flex-direction: column;
  z-index: 10;
  h1 {
    font-size: 50px;
    color: white;
    font-family: Serif, "Garamond", serif;
  }
  span {
    font-family: Serif, "Garamond", serif;
  }
  p {
    font-size: x-large;
    color: white;
  }
`;
