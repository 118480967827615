import React from "react";
import styled from "styled-components";
import GlobalButton from "./GlobalButton";
import left from "./assets/three.png";

const Empower = () => {
  return (
    <Container>
      <Left src={left} />
      <Right>
        <SubTitle>Empower your future</SubTitle>
        <MainTitle>What Andela means to us</MainTitle>
        <Content>
          We exist to unlock human potential at scale. We envision a world where
          the most talented people can build a career commensurate with their
          ability – not their race, gender, or geography.
        </Content>
      </Right>
    </Container>
  );
};

export default Empower;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 700px;
`;
const Left = styled.img`
  width: 38%;
  object-fit: contain;
`;
const Right = styled.div`
  width: 45%;
  height: 70%;
  display: flex;
  flex-direction: column;
  margin-left: 120px;
`;
const SubTitle = styled.div`
  font-size: x-large;
  font-weight: bold;
  margin-top: 20px;
`;
const MainTitle = styled.div`
  font-size: 50px;
  margin-top: 5px;
`;
const Content = styled.div`
  font-size: x-large;
  margin-top: 35px;
`;
const WrapButton = styled.div`
  margin-top: 35px;
`;
