import React from "react";
import styled from "styled-components";
import GlobalButton from "./GlobalButton";

const BrilliantMinds = () => {
  return (
    <Container>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Head>
        <Bold>
          Brilliant minds and companies come
          <br /> together to build the future
        </Bold>
        <Paragraph>
          Meet the team dedicated to helping the world’s best
          <br /> companies build the world’s best teams.
        </Paragraph>
      </Head>
      <br />
      <br />
      <Three />
      <br />
      <br />
      <br />
      <br />
      <br />
    </Container>
  );
};

export default BrilliantMinds;

const Container = styled.div`
  width: 100%;
  background-color: #f8f8f7;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const Head = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;
const Bold = styled.div`
  font-size: 52px;
  text-align: center;
`;
const Paragraph = styled.div`
  font-size: x-large;
  text-align: center;
  margin-top: 35px;
`;
const Three = styled.img`
  width: 100%;
  object-fit: contain;
`;
