import React from "react";
import styled from "styled-components";
import vid from "./assets/vidpic1.jpg";

const Community = () => {
  return (
    <Container>
      <Title>
        <Main>Our vibrant community</Main>
        <Sub>Rizwan Jafri shares his Andela story from Lahore, Pakistan.</Sub>
      </Title>
      <Vid>
        <Img src={vid} />
      </Vid>
    </Container>
  );
};

export default Community;

const Container = styled.div`
  display: flex;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
  flex-direction: column;
  background-color: #173b3f;
`;
const Title = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  flex-wrap: wrap;
`;
const Main = styled.div`
  font-weight: bold;
  font-size: 50px;
  margin-bottom: 20px;
`;
const Sub = styled.div`
  font-size: x-large;
  margin-bottom: 20px;
`;
const Vid = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Img = styled.img`
  object-fit: cover;
  height: 90%;
  width: 50%;
`;
