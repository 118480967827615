import React from "react";
import styled from "styled-components";
import GlobalButton from "./GlobalButton";
import picture from "./assets/manyfaces.jpg";

const Elevate = () => {
  return (
    <Container>
      <Left>
        <Content>
          <h1>
            Elevate your business with in
            <br /> demand skills
          </h1>
          <p>We will help you find the right fit for your business.</p>
        </Content>
        <ButtonHold>
          <GlobalButton
            width="250px"
            height="80px"
            color="black"
            backgroundColor=""
            border="1px solid black"
            borderRadius="50px"
            content="Schedule a call"></GlobalButton>
        </ButtonHold>
      </Left>
      <Right>
        <Foot>
          <Row>
            <Slot>Angular</Slot>
            <Slot>Quality Assurance</Slot>
          </Row>
          <Row>
            <Slot>VueJS</Slot>
            <Slot>PHP</Slot>
          </Row>
          <Row>
            <Slot>Dev Ops</Slot>
            <Slot>React</Slot>
          </Row>
          <Row>
            <Slot>Data Engineers</Slot>
            <Slot>C#</Slot>
          </Row>
          <Row>
            <Slot>Ruby</Slot>
            <Slot>Java</Slot>
          </Row>
        </Foot>
      </Right>
    </Container>
  );
};

export default Elevate;

const ButtonHold = styled.div`
  display: flex;
`;

const Container = styled.div`
  width: 100%;
  height: 600px;
  display: flex;
  align-items: center;
  background-color: #f8f8f8;
`;
const Content = styled.div`
  width: 80%;
  display: flex;
  margin-left: 30px;

  flex-direction: column;
  h1 {
    font-size: 50px;
    font-weight: normal;
  }
  span {
    font-family: Serif, "Garamond", serif;
  }
  p {
    font-size: x-large;
  }
`;
const Left = styled.div`
  width: 50%;
`;
const Right = styled.div`
  width: 50%;
`;
const Foot = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const Row = styled.div`
  width: 97%;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
`;
const Slot = styled.div`
  padding-left: 20px;
  width: 370px;
  height: 70px;
  display: flex;
  align-items: center;
  font-size: x-large;
  border-radius: 40px;
  background-color: white;
  color: #163a2c;
  margin-right: 20px;
  transition: all 350ms;
  :hover {
    transform: scale(1.04);
    cursor: pointer;
    color: #02b102;
  }
`;
