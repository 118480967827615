import React from "react";
import styled from "styled-components";
import GlobalButton from "./GlobalButton";

const KeepAhead = () => {
  return (
    <Container>
      <Head>
        <Bold>Andela keeps you ahead of the curve</Bold>
      </Head>
      <br />
      <br />
      <br />
      <br />
      <Three>
        <Card>
          <Number>110+</Number>
          <Content>
            Countries represented on
            <br /> 6 continents
          </Content>
        </Card>
        <Card>
          <Number>96%</Number>
          <Content>Successful placements</Content>
        </Card>
        <Card>
          <Number>84%</Number>
          <Content>Increased income for Andelans</Content>
        </Card>
      </Three>
      <br />
      <br />
      <Two>
        <Card>
          <Number>175,000+</Number>
          <Content>Technologists represented</Content>
        </Card>
        <Card>
          <Number>18+</Number>
          <Content>
            Months
            <br /> Average engagement
          </Content>
        </Card>
      </Two>
      <br />
      <br />
      <ButtonHold>
        <GlobalButton
          width="210px"
          height="80px"
          color="black"
          backgroundColor="#56c870"
          border=""
          borderRadius="40px"
          content="Find Work"></GlobalButton>
        <GlobalButton
          width="240px"
          height="80px"
          color="black"
          backgroundColor="#56c870"
          border=""
          borderRadius="40px"
          content="Discover Talents"></GlobalButton>
      </ButtonHold>
      <br />
      <br />
      <br />
      <br />
    </Container>
  );
};

export default KeepAhead;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const Head = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;
const Bold = styled.div`
  font-size: 50px;
  text-align: center;
`;
const Paragraph = styled.div`
  font-size: x-large;
  text-align: center;
  margin-top: 35px;
`;
const Three = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;
const Card = styled.div`
  height: 400px;
  width: 500px;
  border-radius: 30px;
  background-color: #a7ccbd;
  color: #163a2c;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;
const Number = styled.div`
  font-size: 65px;
`;
const Content = styled.div`
  font-size: x-large;
  text-align: center;
`;
const Two = styled.div`
  width: 62%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const ButtonHold = styled.div`
  display: flex;
`;
