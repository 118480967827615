import React from "react";
import styled from "styled-components";
import { FcCheckmark } from "react-icons/fc";
import forCard1 from "./assets/bf1.png";
import forCard2 from "./assets/bf2.png";
import forCard3 from "./assets/bf3.png";

const BusinessFindWhat = () => {
  return (
    <Container>
      <Title>
        <Main>Find what you’re looking for and more</Main>
      </Title>
      <Card>
        <LeftCard>
          <Pic src={forCard1} />
        </LeftCard>
        <RightCard>
          <MainTitle>
            Rigorous vetting for talent from multiple regions
          </MainTitle>
          <Content>
            <List>
              <ListItem>
                <FcCheckmark />
                <Text>Quick and efficient</Text>
              </ListItem>
            </List>
            <List>
              <ListItem>
                <FcCheckmark />
                <Text>Only top quality options</Text>
              </ListItem>
            </List>
            <List>
              <ListItem>
                <FcCheckmark />
                <Text>Stress-free hiring of global talent</Text>
              </ListItem>
            </List>
          </Content>
        </RightCard>
      </Card>

      <Card>
        <RightCard>
          <MainTitle>
            The world’s leading companies
            <br />
            are already here
          </MainTitle>
          <Content>
            <List>
              <ListItem>
                <Text>
                  Feel the bustle of the Global Marketplace here at Andela where
                  brilliant minds and companies gather from all over the world.
                </Text>
              </ListItem>
            </List>
          </Content>
        </RightCard>
        <LeftCard>
          <Pic src={forCard2} />
        </LeftCard>
      </Card>
      <Card>
        <LeftCard>
          <Pic src={forCard3} />
        </LeftCard>
        <RightCard>
          <MainTitle>Scale fast and with ease</MainTitle>
          <Content>
            <List>
              <ListItem>
                <FcCheckmark />
                <Text>Onboarding is 70% faster</Text>
              </ListItem>
            </List>
            <List>
              <ListItem>
                <FcCheckmark />
                <Text>We provide additional support</Text>
              </ListItem>
            </List>
            <List>
              <ListItem>
                <FcCheckmark />
                <Text>Get the team you need, regardless of size</Text>
              </ListItem>
            </List>
          </Content>
        </RightCard>
      </Card>
    </Container>
  );
};

export default BusinessFindWhat;
const Container = styled.div`
  display: flex;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
  flex-direction: column;
`;
const Title = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
`;
const Main = styled.div`
  font-size: 50px;
  margin-bottom: 20px;
`;
const Card = styled.div`
  width: 100%;
  display: flex;
  margin-top: 60px;
  margin-bottom: 60px;
`;
const LeftCard = styled.div`
  width: 45%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
  margin-right: 30px;
`;
const RightCard = styled.div`
  width: 46%;
  height: 90%;
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  margin-right: 30px;
`;
const MainTitle = styled.div`
  font-size: 50px;
  margin-top: 7px;
`;
const Content = styled.div`
  font-size: x-large;
  margin-top: 10px;
`;
const List = styled.div`
  margin-top: 5px;
  display: flex;
  align-items: center;
  flex-direction: row;
`;
const ListItem = styled.div`
  margin-top: 4px;
  margin-bottom: 4px;
  display: flex;
`;
const Text = styled.div`
  font-size: x-large;
  color: #6b6b6b;
  margin-left: 7px;
`;
const Pic = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;
