import React from "react";
import styled from "styled-components";

const ScaleFaster = () => {
  return (
    <Container>
      <br />
      <br />
      <br />
      <br />
      <Head>
        <Bold>Scale faster with Andela</Bold>
        <Paragraph>What skills will drive your vision?</Paragraph>
      </Head>
      <Foot>
        <Row>
          <Slot>Python</Slot>
          <Slot>Node.js</Slot>
          <Slot>Kotlin</Slot>
          <Slot>Flask</Slot>
        </Row>
        <Row>
          <Slot>React Native</Slot>
          <Slot>vue.js</Slot>
          <Slot>PHP</Slot>
          <Slot>Android</Slot>
        </Row>
        <Row>
          <Slot>Javascript</Slot>
          <Slot>Ruby</Slot>
          <Slot>C#</Slot>
          <Slot>DevOps</Slot>
        </Row>
        <Row>
          <Slot>Golang</Slot>
          <Slot>Data Scientists</Slot>
          <Slot>React.js</Slot>
          <Slot>Swift</Slot>
        </Row>
        <Row>
          <Slot>Salesforce</Slot>
          <Slot>Java</Slot>
          <Slot>Django</Slot>
        </Row>
      </Foot>
      <br />
      <br />
      <br />
      <br />
    </Container>
  );
};

export default ScaleFaster;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #edeff1;
`;
const Head = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;
const Bold = styled.div`
  font-size: x-large;
  text-align: center;
  font-weight: bold;
`;
const Paragraph = styled.div`
  font-size: 50px;
  text-align: center;
  margin-top: 5px;
`;
const Foot = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
`;
const Row = styled.div`
  width: 97%;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
`;
const Slot = styled.div`
  padding-left: 20px;
  width: 370px;
  height: 70px;
  display: flex;
  align-items: center;
  font-size: x-large;
  border-radius: 40px;
  background-color: white;
  color: #163a2c;
  margin-right: 20px;
  transition: all 350ms;
  :hover {
    transform: scale(1.04);
    cursor: pointer;
    color: #02b102;
  }
`;
